import { BBUIWelcomeHome } from '@bigbearui/index'
import React from 'react'
import { getUserReactQuery } from '../user-queries'

interface Props {}

const HomeHeaderWelcome: React.FC<Props> = ({}) => {
    const { isLoading, isError, data } = getUserReactQuery()

    if (isLoading) {
        return <BBUIWelcomeHome name={'Loading'} />
    }

    if (isError) {
        return <BBUIWelcomeHome name={'Error Loading'} />
    }

    return (
        <>
            <BBUIWelcomeHome name={data.item.name} />
        </>
    )
}

export default HomeHeaderWelcome
