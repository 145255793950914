import React from 'react'

interface Props {
    children: React.ReactNode
    componentId: string
}

const HomeAddons: React.FC<Props> = ({ children, componentId }) => {
    return <>{children}</>
}

export default HomeAddons
