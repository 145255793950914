import { BBUIAxiosQuery, DoorKnockerPalPaths } from '@bigbearui/index'
import { useMutation, useQuery } from '@tanstack/react-query'

export const doorknockerpalUserKeys = {
    all: ['user'] as const,
    lists: () => [...doorknockerpalUserKeys.all, 'list'] as const,
    list: (filters: object) =>
        [...doorknockerpalUserKeys.lists(), filters] as const,
    details: () => [...doorknockerpalUserKeys.all, 'detail'] as const,
    detail: (id: number | string) =>
        [...doorknockerpalUserKeys.details(), id] as const,
}

export const getUserReactQuery = () =>
    useQuery<
        DoorKnockerPalPaths['/doorknockerpal/api/v1/me']['get']['responses']['200']['application/json']['data']['data'],
        Error
    >(
        doorknockerpalUserKeys.detail('me'),
        async () => {
            const response: DoorKnockerPalPaths['/doorknockerpal/api/v1/me']['get']['responses']['200']['application/json'] = await BBUIAxiosQuery(
                {
                    url: `/doorknockerpal/api/v1/me`,
                    params: {},
                    method: 'GET',
                },
            )
            return response.data.data
        },
        { retry: 3 },
    )

export const updateProfileMutationReactQuery = ({}) =>
    useMutation(
        async ({
            files,
            email,
            name,
            country_code,
            timezone,
            user_type,
        }: DoorKnockerPalPaths['/doorknockerpal/api/v1/profile']['put']['requestBody']['application/json']) => {
            const data: DoorKnockerPalPaths['/doorknockerpal/api/v1/profile']['put']['responses']['200']['application/json'] = await BBUIAxiosQuery(
                {
                    url: `/doorknockerpal/api/v1/profile`,
                    data: {
                        files,
                        email,
                        name,
                        country_code,
                        timezone,
                        user_type,
                    },
                    method: 'PUT',
                },
            )
            return data.data
        },
    )

export const getTimezones = () =>
    useQuery<
        DoorKnockerPalPaths['/doorknockerpal/api/v1/timezones']['get']['responses']['200']['application/json']['data']['data'],
        Error
    >(doorknockerpalUserKeys.detail('timezone'), async (key, cursor = 0) => {
        const response: DoorKnockerPalPaths['/doorknockerpal/api/v1/timezones']['get']['responses']['200']['application/json'] = await BBUIAxiosQuery(
            {
                url: `/doorknockerpal/api/v1/timezones`,
                params: {},
                method: 'GET',
            },
        )
        return response.data.data
    })
