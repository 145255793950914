import React from 'react'
import { getUserReactQuery } from '../user-queries'

interface Props {
    children: React.ReactNode
    fallback?: any
}

const CheckForAdminAccess: React.FC<Props> = ({
    children,
    fallback = null,
}) => {
    const { data, isLoading, isError } = getUserReactQuery()

    if (isLoading) {
        return null
    }

    if (isError) {
        return null
    }

    if (!data.item.can_access_admin_console) {
        return fallback
    }

    return children
}

export default CheckForAdminAccess
