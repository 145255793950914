import React from 'react'
import Head from 'next/head'
import { useAuth } from '../../hooks/auth'
import HomeScreen from '../../components/doorknockerpal/home/home-screen'
import DoorKnockerPalLayout from '../../components/Layouts/DoorKnockerPalLayout'

export default function Index() {
    const { user } = useAuth({ middleware: 'auth' })

    return (
        <>
            <Head>
                <title>DoorKnockerPal</title>
            </Head>

            <DoorKnockerPalLayout>
                <HomeScreen componentId="" />
            </DoorKnockerPalLayout>
        </>
    )
}
