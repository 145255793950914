import { BBUIPush, BBUITopbarHome } from '@bigbearui/index'

import HomeHeaderWelcome from './home-header-welcome'
import React from 'react'
import { useRouter } from 'next/router'

interface Props {
    componentId?: string
}

const HomeHeader: React.FC<Props> = ({ componentId = '' }) => {
    const router = useRouter()
    return (
        <>
            <BBUITopbarHome
                title={'Dashboard'}
                onPressSearch={() => {
                    BBUIPush({
                        componentId,
                        path: '/doorknockerpal/search',
                        // reactNativePath: BoxinvySearchIndex.screenName,
                        router,
                    })
                }}
            />
            <HomeHeaderWelcome />
        </>
    )
}

export default HomeHeader
