import {
    BBUIAlertModal,
    BBUIContentHome,
    BBUIContentHomeItem,
    BBUIContentHomeItemSeparator,
    BBUIFooterHome,
    BBUIHomeQuickActions,
    BBUILogout,
    BBUIPage,
    BBUIPush,
    useBBUINiceModalStore,
} from '@bigbearui/index'

import BBUITopBar from '../topbar/bbui-top-bar'
import CheckForAdminAccess from './check-for-admin-access'
import CheckForTeam from './check-for-team'
import HomeAddons from './home-addons'
import HomeHeader from './home-header'
import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'

interface Props {
    componentId: string
}

const HomeScreen: React.FC<Props> = ({ componentId }) => {
    const router = useRouter()
    const queryCache = useQueryClient()
    const { addModal } = useBBUINiceModalStore()
    return (
        <CheckForTeam>
            <HomeAddons componentId={componentId}>
                <BBUIPage
                    testID="scrollView"
                    isScrollable
                    topChildren={<BBUITopBar componentId={componentId} />}>
                    {({}) => {
                        return (
                            <>
                                <HomeHeader componentId={componentId} />

                                <BBUIHomeQuickActions
                                    data={[
                                        {
                                            testID: 'create-door',
                                            id: '1',
                                            icon: 'door-open',
                                            title: 'Create Door',
                                            onPress: () => {
                                                BBUIPush({
                                                    componentId,
                                                    path:
                                                        '/doorknockerpal/doors/create',
                                                    reactNativePath:
                                                        'BBUIUploadingFilesPage',
                                                    router,
                                                })
                                            },
                                        },
                                    ]}
                                />

                                <BBUIContentHome>
                                    {/* <BBUIActionTabs
                    actions={[
                      {
                        id: '0',
                        title: 'Home',
                        onPress: () => {
                          setTab('home');
                        },
                        active: tab === 'home',
                      },
                      {
                        id: '1',
                        title: 'Stats',
                        onPress: () => {
                          setTab('stats');
                        },
                        active: tab === 'stats',
                      },
                    ]}
                  /> */}
                                    <BBUIContentHomeItem
                                        testID="go-door-knocking"
                                        icon="map-marked-alt"
                                        title="Go Door-Knocking"
                                        description="To begin door knocking on the map, click here."
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path: '/doorknockerpal/map',
                                                reactNativePath:
                                                    'DoorKnockerPalMapIndex.screenName',
                                                router,
                                            })
                                        }}
                                    />
                                    <BBUIContentHomeItem
                                        testID="doors"
                                        icon="door-open"
                                        title="Doors"
                                        description="Manage and filter out the doors you've knocked on."
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path: '/doorknockerpal/doors',
                                                reactNativePath:
                                                    'DoorKnockerPalDoorsIndex.screenName',
                                                router,
                                            })
                                        }}
                                    />
                                    <BBUIContentHomeItem
                                        testID="visits"
                                        icon="eye"
                                        title="Visits"
                                        description="Manage and filter your visits based on the doors you've knocked on."
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path: '/doorknockerpal/visits',
                                                reactNativePath:
                                                    'DoorKnockerPalVisitsIndexPage.screenName',
                                                router,
                                            })
                                        }}
                                    />
                                    <BBUIContentHomeItem
                                        testID="tasks"
                                        icon="tasks"
                                        title="Tasks"
                                        description="Add tasks to keep track of what you need to do."
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path: '/doorknockerpal/tasks',
                                                reactNativePath:
                                                    'DoorKnockerPalTasksIndexPage.screenName',
                                                router,
                                            })
                                        }}
                                    />
                                    <BBUIContentHomeItem
                                        testID="recently-used"
                                        icon="magic"
                                        title="Recently Used"
                                        description="View your most recently viewed/used doors and visits."
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path:
                                                    '/doorknockerpal/recently-used',
                                                reactNativePath:
                                                    'DoorKnockerPalRecentlyUsedIndexPage.screenName',
                                                router,
                                            })
                                        }}
                                    />
                                    <BBUIContentHomeItem
                                        testID="bookmarks"
                                        icon="bookmark"
                                        title="Bookmarks"
                                        description="Bookmark doors and visits so you can find them later."
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path:
                                                    '/doorknockerpal/bookmarks',
                                                reactNativePath:
                                                    'DoorKnockerPalBookmarksIndexPage.screenName',
                                                router,
                                            })
                                        }}
                                    />
                                    <BBUIContentHomeItem
                                        testID="leaderboards"
                                        icon="trophy"
                                        title="Leaderboards"
                                        description="View a user leaderboard."
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path:
                                                    '/doorknockerpal/leaderboards',
                                                reactNativePath:
                                                    'DoorKnockerPalLeaderboardsIndexPage.screenName',
                                                router,
                                            })
                                        }}
                                    />
                                    <BBUIContentHomeItem
                                        testID="your-stats"
                                        icon="grin-stars"
                                        title="Your Stats"
                                        description="View a breakdown of your results."
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path:
                                                    '/doorknockerpal/your-stats',
                                                reactNativePath:
                                                    'DoorKnockerPalYourStatsIndexPage.screenName',
                                                router,
                                            })
                                        }}
                                    />
                                    {/* <BBUIContentHomeItem
                    testID="door-sessions"
                    icon="user-clock"
                    title="Door Sessions"
                    description="In a door session, see where you all went."
                    onPress={() => {
                      BBUIPush({
                        componentId,
                        path: '/doorknockerpal/door-sessions',
                        // reactNativePath:
                        //   DoorKnockerPalDoorSessionsIndex.screenName,
                      });
                    }}
                  /> */}
                                    <CheckForAdminAccess>
                                        <>
                                            <BBUIContentHomeItemSeparator title="Admin Console" />
                                            <BBUIContentHomeItem
                                                testID="team-members"
                                                icon="users"
                                                title="Team Members"
                                                description="Manage the people who are already on your team."
                                                onPress={() => {
                                                    BBUIPush({
                                                        componentId,
                                                        path:
                                                            '/doorknockerpal/team-members',
                                                        reactNativePath:
                                                            'DoorKnockerPalTeamMembersIndexPage.screenName',
                                                        router,
                                                    })
                                                }}
                                            />
                                            <BBUIContentHomeItem
                                                testID="team-invites"
                                                icon="user-plus"
                                                title="Team Invites"
                                                description="Send out an email inviting people to join your team."
                                                onPress={() => {
                                                    BBUIPush({
                                                        componentId,
                                                        path:
                                                            '/doorknockerpal/team-invites',
                                                        reactNativePath:
                                                            'DoorKnockerPalTeamInvitesIndexPage.screenName',
                                                        router,
                                                    })
                                                }}
                                            />
                                            <BBUIContentHomeItem
                                                testID="team-settings"
                                                icon="user-cog"
                                                title="Team Settings"
                                                description="Manage the settings for your team."
                                                onPress={() => {
                                                    BBUIPush({
                                                        componentId,
                                                        path:
                                                            '/doorknockerpal/team-settings',
                                                        reactNativePath:
                                                            'DoorKnockerPalTeamSettingsIndexPage.screenName',
                                                        router,
                                                    })
                                                }}
                                            />
                                            <BBUIContentHomeItem
                                                testID="team-stats"
                                                icon="laugh-beam"
                                                title="Team Stats"
                                                description="View an analysis of your team's performance."
                                                onPress={() => {
                                                    BBUIPush({
                                                        componentId,
                                                        path:
                                                            '/doorknockerpal/team-stats',
                                                        reactNativePath:
                                                            'DoorKnockerPalTeamStatsIndexPage.screenName',
                                                        router,
                                                    })
                                                }}
                                            />
                                            <BBUIContentHomeItem
                                                testID="territories"
                                                icon="drafting-compass"
                                                title="Territories"
                                                description="Create a territory to which team members can be assigned."
                                                onPress={() => {
                                                    BBUIPush({
                                                        componentId,
                                                        path:
                                                            '/doorknockerpal/territories',
                                                        reactNativePath:
                                                            'DoorKnockerPalTerritoriesIndexPage.screenName',
                                                        router,
                                                    })
                                                }}
                                            />
                                            <BBUIContentHomeItem
                                                testID="custom-fields"
                                                icon="clipboard-list"
                                                title="Custom Fields"
                                                description="Customize the doors with unique fields."
                                                onPress={() => {
                                                    BBUIPush({
                                                        componentId,
                                                        path:
                                                            '/doorknockerpal/custom-fields',
                                                        reactNativePath:
                                                            'DoorKnockerPalCustomFieldsIndexPage.screenName',
                                                        router,
                                                    })
                                                }}
                                            />
                                            <BBUIContentHomeItem
                                                testID="objections"
                                                icon="hand-paper"
                                                title="Objections"
                                                description="Keep an eye out for objections at the doors."
                                                onPress={() => {
                                                    BBUIPush({
                                                        componentId,
                                                        path:
                                                            '/doorknockerpal/objections',
                                                        reactNativePath:
                                                            'DoorKnockerPalObjectionsIndexPage.screenName',
                                                        router,
                                                    })
                                                }}
                                            />
                                            <BBUIContentHomeItem
                                                testID="custom-status"
                                                icon="map-marker-alt"
                                                title="Custom Status"
                                                description="For your door map pins, you can create custom statuses and icons."
                                                onPress={() => {
                                                    BBUIPush({
                                                        componentId,
                                                        path:
                                                            '/doorknockerpal/custom-status',
                                                        reactNativePath:
                                                            'DoorKnockerPalCustomStatusIndexPage.screenName',
                                                        router,
                                                    })
                                                }}
                                            />
                                            <BBUIContentHomeItem
                                                testID="imports"
                                                icon="file-import"
                                                title="Imports"
                                                description="Import doors from other third-party apps."
                                                onPress={async () => {
                                                    BBUIPush({
                                                        componentId,
                                                        path:
                                                            '/doorknockerpal/imports',
                                                        reactNativePath:
                                                            'DoorKnockerPalCustomStatusIndexPage.screenName',
                                                        router,
                                                    })
                                                }}
                                            />
                                            <BBUIContentHomeItem
                                                testID="exports"
                                                icon="file-export"
                                                title="Exports"
                                                description="Make a CSV file of your doors."
                                                onPress={async () => {
                                                    BBUIPush({
                                                        componentId,
                                                        path:
                                                            '/doorknockerpal/exports',
                                                        reactNativePath:
                                                            'DoorKnockerPalCustomStatusIndexPage.screenName',
                                                        router,
                                                    })
                                                }}
                                            />
                                            <BBUIContentHomeItem
                                                testID="sub-teams"
                                                icon="user-friends"
                                                title="Sub Teams"
                                                description="Sub-teams are smaller groups that work alongside your main team. They make use of the subscription provided by the parent team."
                                                onPress={() => {
                                                    BBUIPush({
                                                        componentId,
                                                        path:
                                                            '/doorknockerpal/sub-teams',
                                                        reactNativePath:
                                                            'DoorKnockerPalSubTeamsIndexPage.screenName',
                                                        router,
                                                    })
                                                }}
                                            />
                                            <BBUIContentHomeItem
                                                testID="team-subscription"
                                                icon="money-bill-alt"
                                                title="Team Subscription"
                                                description="You can manage your DoorKnockerPal Team Subscription here."
                                                onPress={() => {
                                                    BBUIPush({
                                                        componentId,
                                                        path:
                                                            '/doorknockerpal/subscription',
                                                        reactNativePath:
                                                            'DoorKnockerPalSubscriptionIndexPage.screenName',
                                                        router,
                                                    })
                                                }}
                                            />
                                        </>
                                    </CheckForAdminAccess>

                                    <BBUIContentHomeItemSeparator title="Settings" />

                                    <BBUIContentHomeItem
                                        testID="shared-data"
                                        icon="share"
                                        title="Shared Data"
                                        description="Change the data you wish to see for which team members."
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path:
                                                    '/doorknockerpal/shared-data',
                                                reactNativePath:
                                                    'DoorKnockerPalSharedDataIndexPage.screenName',
                                                router,
                                            })
                                        }}
                                    />
                                    <BBUIContentHomeItem
                                        testID="teams"
                                        icon="users"
                                        title="Teams"
                                        description="View all of your teams, switch between them, and create new ones!"
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path: '/doorknockerpal/teams',
                                                reactNativePath:
                                                    'DoorKnockerPalTeamsIndexPage.screenName',
                                                router,
                                            })
                                        }}
                                    />
                                    <BBUIContentHomeItem
                                        testID="edit-profile"
                                        icon="user-circle"
                                        title="Edit Profile"
                                        description="Change the information on your profile."
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path: '/doorknockerpal/profile',
                                                reactNativePath:
                                                    'DoorKnockerPalProfileIndexPage.screenName',
                                                router,
                                            })
                                        }}
                                    />

                                    <BBUIContentHomeItemSeparator title="More" />

                                    <BBUIContentHomeItem
                                        testID="chat-with-us"
                                        icon="comments"
                                        title="Support"
                                        description="Chat with us on our community forum"
                                        onPress={() => {
                                            ;(window as any).open(
                                                'https://community.bigbeartechworld.com/c/doorknockerpal/18',
                                                '_blank',
                                            )
                                        }}
                                    />
                                    <BBUIContentHomeItem
                                        testID="logout"
                                        icon="sign-out-alt"
                                        title="Logout"
                                        description="Logout of Boxinvy"
                                        onPress={() => {
                                            BBUIPush({
                                                path: BBUIAlertModal,
                                                isModal: true,
                                                addModal,
                                                router,
                                                state: {
                                                    title: 'Are you sure?',
                                                    message:
                                                        'You want to logout?',
                                                    buttons: [
                                                        {
                                                            testID:
                                                                'cancel-button',
                                                            text: 'Cancel',
                                                            onPress: () => {},
                                                            style: 'cancel',
                                                        },
                                                        {
                                                            testID:
                                                                'yes-button',
                                                            text: 'Yes',
                                                            onPress: () => {
                                                                BBUILogout({
                                                                    queryCache,
                                                                })
                                                                window.location.href = `/accounts/login?redirect=${window.location.href}`
                                                            },
                                                        },
                                                    ],
                                                    options: {
                                                        cancelable: false,
                                                    },
                                                },
                                            })
                                        }}
                                    />
                                    <BBUIContentHomeItem
                                        testID="my-account"
                                        icon="user-circle"
                                        title="BigBearApps"
                                        description="Navigate to BigBearApps and Services."
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path: '/',
                                                router,
                                            })
                                        }}
                                    />
                                </BBUIContentHome>

                                <BBUIFooterHome
                                    title={'DoorKnockerPal'}
                                    version={process.env.APP_VERSION}
                                />
                            </>
                        )
                    }}
                </BBUIPage>
            </HomeAddons>
        </CheckForTeam>
    )
}

export default HomeScreen
